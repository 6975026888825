@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

.modal-body::-webkit-scrollbar {
  @apply w-0 h-0 cursor-pointer;
}

@media (min-width: 1024px) {
  html {
    scroll-padding-top: 171px;
  }
}
